

.div_logo {
    position: absolute;
    left: auto;
    top: auto;
    z-index: 0;
  }
  
  .logo-esperar {
    animation: Esp-logo-spin infinite 2s linear alternate;
    width: 38vmin;
    pointer-events: none;
    margin-left: auto;
    margin-right: auto;
  }
  
  @keyframes Esp-logo-spin {
    from {
      opacity: 0.2
    }
    to {
      opacity: 1
    }
  }
  
  
  .texto {
    margin-left: auto;
    margin-right : auto;
    color: #282c34;
    font-size: calc(30px + 2vmin);
  }
  
  .App-mis-datos {
    background-color: #282c34;
    display: flex;
    padding-right: 10px;
  
    justify-content:flex-end;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  